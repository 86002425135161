import React, { useState, useRef, useEffect } from 'react';
import '../../styles/Kasa.scss';
import imageKasa from '../../assets/Banner-Kasa1.png';

function ProKasa() {
  const sliderRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add('active');
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Ajustez la vitesse de défilement comme souhaité
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  const lienAOuvrir = (element) => {
    window.open(element, '_blank');
  };

  return (
    <section
    className='sectionProjets sectionProjetsKasa'
    ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}>

      <div id="image-track" >
      <img
           className=" couverture couvertureKasa "
           src={imageKasa}
           alt=""
           draggable="false"/>

        <article className='kasaIntro'>

          <div>
          <img src="https://images.unsplash.com/photo-1617806118233-18e1de247200?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  
            <p>
            Kasa m'a recruté en tant que développeur front-end en freelance pour développer sa nouvelle plateforme web. <br />
            Kasa est dans le métier de la location d’appartements entre particuliers depuis près de 10 ans maintenant. <br /> 
            Avec plus de 500 annonces postées chaque jour, 
            Kasa fait partie des leaders de la location d’appartements entre particuliers en France.
            </p>
          </div>

           <p>
           Le site de Kasa avait été codé il y a plus de 10 ans en ASP.NET, 
           
           avec un code legacy important. <br />
    Laura, la CTO, a donc lancé une refonte totale pour passer à une stack complète en JavaScript, 
    avec NodeJS côté back-end et React côté front-end. <br />
    Kasa en a également profité pour commander de nouvelles maquettes auprès de son designer habituel, 
    qui est en freelance. <br />
    Un gros chantier pour cette année ! <br />
    Un point était prévu avec la CTO plus tard dans la semaine, 
    mais pour que je puisse me familiariser avec les enjeux de ma mission, 
    j'ai trouvé le récapitulatif suivant dans ma boîte mail :

          </p>

        </article>

        <article className='articleMessage messageLaura'>

          <p className='messageLaura-1'>" Hello,
Bienvenue pour ton premier jour ! <br />
On est vraiment ravis de te compter parmi nous ! <br />
Tu vas pouvoir nous aider à donner vie à un chantier sur lequel on travaille depuis plusieurs mois. <br />
Je préfère te mettre par écrit quelques éléments de contexte qui t'aideront à te projeter dans ta mission avant notre point de jeudi. "</p>

<div className='messageLaura-2'>
  <p>" Ton objectif : <br />
    Démarrer le projet React et développer l’ensemble de l’application, 
    les composants React, les routes React Router, 
    en suivant les maquettes Figma (responsives !) et toutes les infos que je te donne ci-dessous. <br />
   Et ce avec un code de qualité !  "
   </p>

  <p>" Back-end / data : <br />
  Le recrutement de la personne en charge du back-end n'est pas terminé et va prendre plus de temps que prévu. <br />
   Du coup, il va falloir que tu fasses sans pour le moment. <br />
  Je t'ai extrait les 20 dernières annonces de logements dans ce fichier JSON pour que tu puisses construire le Front qui correspond. "</p>
  <p>" Le code front-end d’origine avec la version statique de la page de l’architecte "</p>
  
<p>
Contraintes techniques : <br />
Tu trouveras les coding guidelines de Kasa ici. <br />
Comme tu le verras, nous utilisons habituellement Create React App pour créer les applications React, 
mais tu peux utiliser un autre bundler comme Vite par exemple si tu préfères. <br />
Voilà, j'espère que ce petit récapitulatif t'aura donné suffisamment de pistes pour aborder sereinement tes premiers jours chez nous.

Très bonne journée à toi !

Laura
</p>

</div>
        </article>


        <article className='messagePaul2' >

     <div className='messagePaulBlocs'>     
<p>" Salut, <br />
Bienvenue parmi nous ! <br /> Laura m’a dit de te briefer sur le design de la nouvelle version du site, alors voici les infos clés. "</p>
  <img 
  src="https://images.unsplash.com/photo-1614649024145-7f847b1c803f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
   draggable="false" 
   alt=""/>
  
</div>

<div className='messagePaulBlocs'>
  <p>" Design <br />

Voici les maquettes sur Figma pour le design d’interface. <br />
J’ai l’habitude de travailler avec la logique de composants sur Figma ; <br />
Sandra m’a dit que ça te faciliterait le travail sur React. <br />
Tu trouveras toutes les ressources dont tu as besoin directement dans la maquette (logo, icônes pour les composants, etc.). <br />
Pour cela, 
il suffit de cliquer sur la ressource souhaitée et de faire "Exporter" au format voulu. <br />
Sur les vignettes des logements, 
pour les images de couverture, 
j'ai mis une rectangle orange que tu peux remplacer par l'image correspondante. <br />
Pour avoir un rendu le plus réaliste possible de l'application, 
utilise les prototypes du site disponibles ici. <br />
Ils te permettront notamment de voir les animations attendues sur les menus déroulants (Collapse).   </p>

<img onClick={() => lienAOuvrir("https://www.figma.com/file/qEno0LwL4ZLkWyeY59kxp1/UI-Design-Kasa-FR?type=design&node-id=0-1&mode=design")}
  src={require('../../assets/maquette-Kasa.png')} 
  draggable="false" 
  alt=""/>
</div>



<div className='messagePaulBlocs'>
  <p>" Contraintes fonctionnelles <br />

Quelques précisions sur les fonctionnalités du site : <br />

    Pour le défilement des photos dans la galerie (composant Gallery) : <br />
        Si l'utilisateur se trouve à la première image et qu'il clique sur "Image précédente",
         la galerie affiche la dernière image. <br />
        Inversement, 
        quand l'image affichée est la dernière de la galerie, 
        si l'utilisateur clique sur "Image suivante", la galerie affiche la première image. <br />
        S'il n'y a qu'une seule image, 
        les boutons "Suivant" et "Précédent" ainsi que la numérotation n'apparaissent pas. <br />
    La galerie doit toujours rester de la même hauteur, 
    celle indiquée sur la maquette Figma. Les images seront donc coupées et centrées dans le cadre de l’image. <br />
    Collapse : <br />
    Par défaut, 
    les Collapses sont fermés à l'initialisation de la page. <br />
    Si le Collapse est ouvert, 
    le clic de l'utilisateur permet de le fermer. <br />
        Inversement, 
        si le Collapse est fermé, 
        un clic permet de l'ouvrir. <br />

Bon courage pour le développement, 
j’ai hâte de voir ce que ça va donner ! <br />

Paul
</p>
<img src="https://images.unsplash.com/photo-1590725121839-892b458a74fe?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGFpcmJuYnxlbnwwfHwwfHx8MA%3D%3D" 
draggable="false" 
alt=""/>
  
</div>

        </article>


<article className='articleVideoPresentation VideoKasa'>

  <h2>
    Cette video est là pour vous expliquer plus en details.
  </h2>
<video draggable="false" controls>
  <source src={require('../../assets/kasa.mp4')} type="video/mp4" />
  Your browser does not support the video tag.
</video>


</article>

<article className='articleSite articleSiteKasa'>
<h2 onClick={() => lienAOuvrir('https://github.com/Yacoubbeltanivelle/Kasa')}>Testez vous meme !</h2>
  <img draggable="false" src={require('../../assets/site-Kasa.png')} alt="" />
</article>

     </div>

  </section>

  );
}

export default ProKasa;
