import '../../styles/Home.scss';
import React, { Suspense, lazy } from 'react';
import Logo from '../../assets/Logo.svg';
import { Link } from 'react-router-dom';
import CV from '../../assets/CVYacoubDéveloppeur-webReact.pdf';

const Spline = lazy(() => import('@splinetool/react-spline'));

const ouvrirCV = (element) => {
  window.open(element, '_blank');
};

function Home() {
  return (
    <main className="home">
      <img className='home-logo' src={Logo} alt="Logo" />
      <section className='home-titre'>
        <h1>Je souhaite voir votre succès. <br></br> Dans ce cas, qu'attendons-nous?</h1>
        

        <div className='home-titre-infos'>

          <Link
            className='home-titre-sousInfos'
            to='#'
            onClick={() => ouvrirCV(CV)}
          >Cv</Link>

          <span className='home-titre-sousInfos home-titre--integrateur'>Integrateur Web React</span>
          <Link className='home-titre-sousInfos' to="https://github.com/Yacoubbeltanivelle" target="_blank">GitHub</Link>

        </div>
      </section>

      <Suspense fallback={<div>Loading...</div>}>
        <Spline scene="https://prod.spline.design/OvNSLGw5lVUKuNdM/scene.splinecode" />
      </Suspense>
    </main>
  );
}

export default Home;

