import '../../styles/Booki.scss';
import { useState, useRef } from 'react';
import imagebooki from '../../assets/Banner-Booki.png';
import moi from '../../assets/moi.svg';
import sarah from '../../assets/Sarah.svg';
import loic from '../../assets/Loïc.svg';
import maquette from '../../assets/maquette-booki.png';
import synthese from '../../assets/synthese.png';



function ProBooki() {

  const sliderRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add('active');
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Ajustez la vitesse de défilement comme souhaité
    sliderRef.current.scrollLeft = scrollLeft - walk;

  };

    const lienAOuvrir = (element) => {
      window.open(element, '_blank');
    };

  return (
    <section
    className='sectionProjets'
    ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}>

      <div id="image-track" >

        <img
           className="couverture imagebooki"
           src={imagebooki}
           alt=""
           draggable="false"/>

        <article className='articleIntro'>
           <p>
          Je débute mon alternance en tant que développeur web au sein de la start-up Booki.
          L'entreprise a pour objectif de créer un site Internet qui offrira aux utilisateurs la possibilité de trouver des hébergements et des activités dans la ville de leur choix. 
          <br /> <img className='img-intro' src={moi} alt="Moi" draggable="false"/> On m'a confié la tâche d'intégrer l'interface du site en utilisant du code HTML et CSS.
          Pour accomplir cette mission, je collabore étroitement avec Sarah, La CTO, ainsi qu'avec Loïc, L'UI designer.
          Sarah m'a envoyé un e-mail pour me présenter l'avancement du projet.
          </p>

        </article>

        <article className='articleMessage articleMessageSarah'>

        <div className='messageSarah'>
          <div className='messageSarah-texte'>
              <p>
                <img className='img-equipe imgSarah' src={sarah} alt="Sarah" draggable="false"/>
                "Bonjour,
                Ça y est, Loïc a finalisé les maquettes desktop, tablette et mobile du site !
                Tu vas pouvoir les intégrer.
                Elles sont disponibles en pièces jointes sur Figma.
                Tu trouveras également en pièce jointe le dossier contenant toutes les images à utiliser sur le site."
              </p>
            </div>

            <div className='messageSarah-contenu' onClick={() => lienAOuvrir(maquette)}>
      <img src={maquette} alt="" draggable="false"/>
    </div>
        </div>

        <div className='messageSarah'>
          <div className='messageSarah-contenu Message--contenu-B'>
              <img src={synthese} alt="" draggable="false"/>
            </div>
          <div className='messageSarah-texte Message-B'>
              <p>
                <img className='img-equipe imgSarah' src={sarah} alt="Sarah" draggable="false"/>
                <img className='img-equipe imgSarah' src={loic} alt="Loïc" draggable="false"/>
                "Pour que le projet soit très clair,
                Loïc et moi t’avons préparé une note de synthèse qui regroupe les spécifications
                et contraintes techniques à respecter (voir pièce jointe)."
              </p>
            </div>
        </div>

      </article>

      <article className='articleMessage articleMessageSarah--2'>

        <div className='articleMessageSarah2--textes'>
    <div className='messageSarah2_textes-divs'>
      <p>
        <img className='imgSarah' src={sarah} alt="Sarah" draggable="false" />
        "Pour que nous puissions consulter le site une fois fini, il faudra que tu crées un dossier, compressé
au format ZIP, avec :
<br /> un fichier “index.html” contenant l’ensemble du code HTML du projet ;
<br /> un dossier “CSS” comprenant un fichier “style.css” contenant l’ensemble du code CSS du projet ;
<br /> un dossier “images” contenant l’ensemble des images utilisées.
<br /> Bon courage pour ce projet, et bonne journée !
<br /> Sarah, CTO @Booki
"
      </p>
    </div>
  </div>

  <div className='messageSarah--2'>
    <div className='photo-ronde'>
      <img alt='' draggable="false" src="https://images.unsplash.com/photo-1540539234-c14a20fb7c7b?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
    </div>
    <div className='photo-rectangle'>
      <img alt='' draggable="false" src="https://plus.unsplash.com/premium_photo-1676140621471-cc2198c17848?auto=format&fit=crop&q=80&w=2069&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
    </div>
  </div>

  <div className='messageSarah--2'>
    <div className='photo-rectangle photo-rectangle--2'>
      <img alt='' draggable="false" src="https://images.unsplash.com/photo-1569965335962-2317ff2a7658?auto=format&fit=crop&q=80&w=1938&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"/>
    </div>
    <div className='photo-ronde photo-ronde--2'>
      <img alt='' draggable="false" src="https://images.unsplash.com/photo-1595917513241-e9d7a9d8f0a0?auto=format&fit=crop&q=80&w=1991&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1pYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
    </div>
  </div>

</article>

<article className='articleVideoPresentation'>

  <h1>
    Cette video est là pour vous expliquer plus en details.
  </h1>
<video draggable="false" controls>
  <source src={require('../../assets/booki.mp4')} type="video/mp4" />
  Your browser does not support the video tag.
</video>


</article>

<article className='articleSite'>
  <h2 onClick={() => lienAOuvrir('https://yacoubbeltanivelle.github.io/Booki/')}>Testez vous meme !</h2>
  <img draggable="false" src={require('../../assets/site-booki.png')} alt="" />
</article>

     </div>

  </section>

  );
}

export default ProBooki;
