import '../../styles/Footer.scss';
import { useLocation } from 'react-router-dom';


function Footer() {
  const location = useLocation();
  const pageActuel = location.pathname;

  return (
    <footer className={`menu-item ${pageActuel === '/' ? 'footer' : 'footerNone'}`}>
      <p>Mentions légales</p>
    </footer>
  );
}

export default Footer;
