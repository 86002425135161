import '../../styles/Header.scss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CV from '../../assets/CVYacoubDéveloppeur-webReact.pdf';


const Header = () => {
  const location = useLocation();
  const pageActuel = location.pathname;

  const ouvrirCV = (element) => {
    window.open(element, '_blank');
  };

  return (
    <header className="menu-principale">

      <nav id={`${pageActuel === '/' ? '' : 'navBlanc'}`}>
        <ul>
  
          <li>
            <Link onClick={() => ouvrirCV(CV) } to={''} >Contacts</Link>
          </li>

          <li className="menu-principale-projetBtn" >
            <Link to={pageActuel === '/Projets' ? '/' : '/Projets'}>{pageActuel === '/Projets' ? 'Retour' : 'Projets'}</Link>
          </li>

            <li>
            <Link to={pageActuel === '/QuiSuisJe' ? '/' : '/QuiSuisJe'}>{pageActuel === '/QuiSuisJe' ? 'Retour' : 'Qui suis-je?'}</Link>
          </li>

        </ul>
      </nav>
    </header>
  );
};

export default Header;
