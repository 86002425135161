import React, { useRef } from 'react';
import '../../styles/MenuProjets.scss'; // Importez votre feuille de style
import imageBooki from '../../assets/Banner-Booki.png';
import imageOhmyfood from '../../assets/Banner-Ohmyfood.png';
import imageSophiebluel from '../../assets/sophie-bluel.png';
import imageKasa from '../../assets/Banner-Kasa.png';

function MenuProjets({ onProjetClick }) {
  const menuRef = useRef(null);

  const handleOnDown = (e) => {
    menuRef.current.dataset.mouseDownAt = e.clientX;
  };

  const handleOnUp = () => {
    menuRef.current.dataset.mouseDownAt = "0";
    menuRef.current.dataset.prevPercentage = menuRef.current.dataset.percentage;
  };

  const handleOnMove = (e) => {
    if (menuRef.current.dataset.mouseDownAt === "0") return;

    const mouseDelta = parseFloat(menuRef.current.dataset.mouseDownAt) - e.clientX;
    const maxDelta = window.innerWidth / 2;

    const percentage = (mouseDelta / maxDelta) * -100;
    const nextPercentageUnconstrained = parseFloat(menuRef.current.dataset.prevPercentage) + percentage;
    const nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

    menuRef.current.dataset.percentage = nextPercentage;

    const menu = document.getElementById("MenuProjets");
    menu.animate({
      transform: `translate(${nextPercentage}%, -50%)`
    }, { duration: 1200, fill: "forwards" });

  };

  return (
    <div
      id="MenuProjets"
      ref={menuRef}
      data-mouse-down-at="0"
      data-prev-percentage="0"
      onMouseDown={handleOnDown}
      onMouseUp={handleOnUp}
      onMouseMove={handleOnMove}
    >

      <img
      className='image'
      onClick={() => onProjetClick('ProBooki')}
      src={imageBooki}
      alt="Projet-Booki"
      draggable="false"/>

      <img
      className='image'
      onClick={() => onProjetClick('ProOhmyfood')}
      src={imageOhmyfood}
      alt="Projet-Ohmyfood"
      draggable="false"/>

      <img
      className='image'
      onClick={() => onProjetClick('ProSophieBluel')}
      src={imageSophiebluel}
      alt="Projet-Sophie-bluel"
      draggable="false"/>

      <img
      className='image'
      onClick={() => onProjetClick('ProKasa')}
      src={imageKasa}
      alt="Projet-Kasa"
      draggable="false"/>

    </div>
  );
}

export default MenuProjets;
