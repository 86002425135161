import React, { useState } from 'react';
import MenuProjets from '../../components/MenuProjets';
import ProBooki from '../../components/PagesProjets/ProBooki';
import ProjetB from '../../components/PagesProjets/ProOhmyfood';
import ProSophieBluel from '../../components/PagesProjets/ProSophieBluel';
import ProKasa from '../../components/PagesProjets/ProKasa';
import '../../styles/Projets.scss';

function Projet() {
  const [selectedProjet, setSelectedProjet] = useState('ProBooki');

  const handleProjetClick = (projetName) => {
    setSelectedProjet(projetName);
  };

  return (
    <main>
  
      <MenuProjets onProjetClick={handleProjetClick} />
      {selectedProjet === 'ProBooki' && <ProBooki />}
      {selectedProjet === 'ProOhmyfood' && <ProjetB />}
      {selectedProjet === 'ProSophieBluel' && <ProSophieBluel />}
      {selectedProjet === 'ProKasa' && <ProKasa />}
    </main>
  );
}

export default Projet;
