import '../../styles/quisuisje.scss';
import React from 'react';
import Logo from '../../assets/Logo.svg';
import { Link } from 'react-router-dom';
import CV from '../../assets/CVYacoubDéveloppeur-webReact.pdf';


const ouvrirCV = (element) => {
  window.open(element, '_blank');
};

function QuiSuisJe() {
  return (
    <main className="">
      <img className='home-logo' src={Logo} alt="Logo" />
      <section className='quisuisje-texte'>
        <p>Salutation,<br /><br />
Je suis Yacoub BELTA-NIVELLE, 
un passionné de l'intégration web et du développement Front-end, 
basé à seulement 30 minutes de Paris.<br />
Mon aventure dans le monde du code a débuté en 2013 sur "Le Site du Zéro", 
aujourd'hui rebaptisé "OpenClassrooms". <br />
Depuis, 
j'ai exploré les méandres du HTML, 
du CSS, 
et du PHP, 
enrichissant ainsi mes compétences au fil des années.<br />
À l'âge de 16 ans, 
pendant mes années lycée, 
j'ai concrétisé ma passion en créant un réseau social artistique en PHP. <br />
Ce projet avait pour ambition de faciliter la communication entre artistes, 
favoriser l'interaction, 
le soutien mutuel, 
et encourager le lancement de projets collaboratifs. <br />
Si vous le souhaitez, 
je serais ravi de partager davantage sur cette expérience.<br /><br />
Ce qui me fascine dans le domaine informatique, 
c'est la dimension créative et innovante. <br />
C'est une vocation pour moi, 
une opportunité d'aider le monde à s'améliorer, 
de travailler ensemble vers des objectifs communs.<br /><br />
Aujourd'hui, 
je mets mes compétences au service de ceux qui en ont besoin. <br />
Vous pouvez télécharger mon <Link to='#'onClick={() => ouvrirCV(CV)}>Cv</Link> et explorer mes projets sur <Link to="https://github.com/Yacoubbeltanivelle" target="_blank">GitHub</Link> pour en savoir plus. <br /><br />
N'hésitez pas à me contacter pour toute demande ou collaboration.<br />
Alors, 
n'hésitez pas, 
à très bientôt !</p>

      </section>

    </main>
  );
}

export default QuiSuisJe;

