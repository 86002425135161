import React, { useState, useRef, useEffect } from 'react';
import '../../styles/SophieBluel.scss';
import kanban from '../../assets/kanban-Sophie.png';

function ProSophieBluel() {
  const sliderRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add('active');
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Ajustez la vitesse de défilement comme souhaité
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const gallery = document.getElementById("gallery");

    const handleMouseMotion = (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      const xDecimal = mouseX / window.innerWidth;
      const yDecimal = mouseY / window.innerHeight;

      const maxX = gallery.offsetWidth - window.innerWidth;
      const maxY = gallery.offsetHeight - window.innerHeight;

      const panX = maxX * xDecimal * -1;
      const panY = maxY * yDecimal * -1;

      gallery.animate(
        {
          transform: `translate(${panX}px, ${panY}px)`
        },
        {
          duration: 40000,
          fill: "backwards",
          easing: "ease"
        }
      );
    };

    window.addEventListener("mousemove", handleMouseMotion);

    return () => {
      window.removeEventListener("mousemove", handleMouseMotion);
    };
  }, []);


  const lienAOuvrir = (element) => {
    window.open(element, '_blank');
  };

  return (
    <section
    className='sectionProjets sectionProjetsSophieBluel'
    ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}>

      <div id="image-track" >

      <div id="gallery">
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1617806118233-18e1de247200?auto=format&fit=crop&q=80&w=1932&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1606744837616-56c9a5c6a6eb?auto=format&fit=crop&q=80&w=1935&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1652932374553-3ee7a4343a2a?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1635611320161-e16b1b6f1c2a?auto=format&fit=crop&q=80&w=1964&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1634382615649-7227ce9a5cee?auto=format&fit=crop&q=80&w=2080&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1625671680827-fdc3014d516d?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1628067630818-fa3e19d96b27?auto=format&fit=crop&q=80&w=1964&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://plus.unsplash.com/premium_photo-1672109012124-4a8a760284ed?auto=format&fit=crop&q=80&w=2128&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  <div class="tile">
    <img src="https://images.unsplash.com/photo-1642950810976-e0a9926389ab?auto=format&fit=crop&q=80&w=1966&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" draggable="false" alt=""/>
  </div>
  

</div>
<article className='sophieTitre' >
  <h1>Portfolio de l'architecte Sophie bluel</h1>
</article>

        <article className='sophieIntro'>
           <p>
           Je travaille comme développeur front-end pour l’agence ArchiWebos qui comprend 50 salariés. <br />
Je suis envoyé en renfort comme développeur front-end d’une équipe qui travaille sur la conception du site portfolio d’une architecte d’intérieur.
          </p>

        </article>

        <article className='articleMessage messageCharlotte'>

          <p className='messageParagraphre-1'>" Re, <br />
On est tous contents que tu nous aies rejoints et que tu nous prêtes main-forte pour le Front du site. <br />
Je te mets en pièces jointes tout ce dont on a parlé pendant la réunion : "</p>

<div className='messageParagraphre-2'>
  <p>" Le Figma du design qui te servira à créer les nouvelles fonctionnalités "</p>
  <p>" Le code back-end qui te permettra de faire persister les données et de tester les fonctionnalités "</p>
  <p>" Le code front-end d’origine avec la version statique de la page de l’architecte "</p>

  <img onClick={() => lienAOuvrir("https://www.figma.com/file/kfKHknHySoTibZfdolGAX6/Sophie-Bluel---Desktop?type=design&node-id=0-1&mode=design")}
  src={require('../../assets/maquette-Sophie.png')} 
  draggable="false" 
  alt=""/>
  
</div>

<div className='messageParagraphre-3'>
  <p>" Le Kanban pour la mission, pour que tu puisses voir les tâches qui te sont attribuées. "</p>
  <img 
  onClick={() => lienAOuvrir(kanban)}
  src={kanban}   
  draggable="false" 
  alt=""/>
  
</div>
        </article>

        <article className='mission' >

        <img 
        src={require('../../assets/mission-Sophie.png')}
        draggable="false" 
        alt=""/>

          <div>
            <h2>La mission est donc de developper :</h2>
          <p>
    -La page de présentation des travaux de l'architecte (à partir du HTML fourni)<br /><br />
    -La page de connexion de l'administrateur du site (le client) (code à créer de zéro) <br /><br />
    -La modale permettant d'uploader de nouveaux médias <br /><br />
          </p>
          </div>

        </article>


<article className='articleVideoPresentation VideoSophie'>

  <h2>
    Cette video est là pour vous expliquer plus en details.
  </h2>
<video draggable="false" controls>
  <source src={require('../../assets/sophie.mp4')} type="video/mp4" />
  Your browser does not support the video tag.
</video>


</article>

<article className='articleSite articleSiteSophie'>
<h2 onClick={() => lienAOuvrir('https://github.com/Yacoubbeltanivelle/Portfolio-architecte-sophie-bluel-Yacoub')}>Testez vous meme !</h2>
  <img draggable="false" src={require('../../assets/site-Sophie.png')} alt="" />
</article>

     </div>

  </section>

  );
}

export default ProSophieBluel;
