import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import './styles/index.scss';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Erreur from './pages/Erreur';
import Projets from './pages/Projets';
import QuiSuisJe from './pages/QuiSuisJe';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  <React.StrictMode>
    <Router>

      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Projets" element={<Projets/>} />
        <Route path="/QuiSuisJe" element={<QuiSuisJe/>} />
        <Route path="erreur" element={<Erreur />} />
        <Route path="*" element={<Navigate to="/erreur" />} />
      </Routes>
      <Footer />

    </Router>
  </React.StrictMode>
);
