import '../../styles/Ohmyfood.scss';
import { useState, useRef } from 'react';
import imageOhmyfood from '../../assets/Banner-Ohmyfood1.png';
import MaquettesOhmyfood from '../../assets/MaquettesOhmyfood.png';



function ProBooki() {

  const sliderRef = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add('active');
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseUp = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Ajustez la vitesse de défilement comme souhaité
    sliderRef.current.scrollLeft = scrollLeft - walk;

  };

  const lienAOuvrir = (element) => {
    window.open(element, '_blank');
  };

  return (
    <section
    className='sectionProjets'
    ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}>

      <div id="image-track" >

        <img
           className="couverture imageOhmyfood"
           src={imageOhmyfood}
           alt=""
           draggable="false"/>

        <article className='introFood'>
           <p>
           J'ai intégré Ohmyfood en tant que développeur junior. <br />
           Il s'agissait d'une jeune startup qui souhaitait s'imposer sur le marché de la restauration. <br />
           Déjà présente à New-York, elle voulait désormais faire sa place à Paris.<br />
Ma mission était de développer un site "mobile first" qui répertoriait les menus de restaurants gastronomiques. <br />
Paul avait alors décidé que le site contiendrait 4 menus dans un premier temps.<br />
À deux, nous avions pu établir ce brief créatif pour rassembler toutes les informations clés du projet de développement du site.<br />
Paul nous avait envoyé les maquettes par e-mail.
          </p>

        </article>

        <article className='articleMessage articleMessagePaul'>

        <div className='messagePaul'>

          <div className='messagePaul-contenu contenu1'>
          <img
           className=""
           src="https://images.unsplash.com/photo-1593854989775-ae5e4d9e49e9?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
           alt=""
           draggable="false"/>
            </div>
          <div className='messagePaul-texte'>
              <p>
                "Bonjour !
L’UX designer a finalisé les maquettes mobile et desktop du site !
Tu les trouveras en pièce jointe, en plus du dossier des sources du site (images et textes).
Tu y trouveras également le prototype du site via Figma, incluant les animations et comportements à intégrer.
Voici un extrait de ce à quoi devra ressembler le site :"
              </p>
            </div>

            <div className='messagePaul-contenu contenu2'>
            <img
           className=""
           src="https://images.unsplash.com/photo-1559847844-5315695dadae?q=80&w=2058&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
           alt=""
           draggable="false"/>
            </div>
        </div>

        <img 
           className="MaquettesOhmyfood"
           src={MaquettesOhmyfood}
           alt=""
           draggable="false"
           onClick={() => lienAOuvrir(MaquettesOhmyfood)}/>

        <div className='messagePaul'>

<div className='messagePaul-contenu contenu1'>
<img
           className=""
           src="https://images.unsplash.com/photo-1557499305-0af888c3d8ec?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
           alt=""
           draggable="false"/>
  </div>
<div className='messagePaul-texte Paul-texte2'>
    <p>
      "Il n’y a plus qu’à le développer pour mobile, 
      tablette et desktop en s’appuyant rigoureusement sur les informations déterminées dans le brief créatif ! 
      Pour rappel, le site doit être responsive et en “mobile first”. 
      On veut aussi des animations soignées. 
      Il faudra que tu m’expliques comment elles fonctionnent.
Hâte de voir le site terminé ! 
Bon courage ! <br />
Paul"
    </p>
  </div>

  <div className='messagePaul-contenu contenu2'>
  <img
           className=""
           src="https://images.unsplash.com/photo-1625938393850-10f1f23de467?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
           alt=""
           draggable="false"/>
  </div>
</div>

      </article>


<article className='articleVideoPresentation VideoOhmyfood'>

  <h1>
    Cette video est là pour vous expliquer plus en details.
  </h1>
<video draggable="false" controls>
  <source src={require('../../assets/ohmyfood.mp4')} type="video/mp4" />
  Your browser does not support the video tag.
</video>


</article>

<article className='articleSite articleSiteOhmyfood'>
<h2 onClick={() => lienAOuvrir('https://yacoubbeltanivelle.github.io/Ohmyfood/')}>Testez vous meme !</h2>
  <img draggable="false" src={require('../../assets/site-Ohmyfood.png')} alt="" />
</article>

     </div>

  </section>

  );
}

export default ProBooki;
